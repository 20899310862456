import React from "react";

const preguntasFrecuentesNQN = () => {
  return (
    <div className=" mx-auto" id="accordionQA">
      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA1"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA1"
              aria-expanded="false"
              aria-controls="collapseQA1"
            >
              Sobre la Registración
            </div>
          </h5>
        </div>

        <div
          id="collapseQA1"
          className="collapse show"
          aria-labelledby="QA1"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">¿Cómo me registro?</legend>
            <p className="text-small text-justify">
              Podes registrarte desde tu celular pinchando la opción
              "Registrarse" en la pantalla de Inicio.
            </p>

            <legend className="text-center mb-3">
              ¿Por qué tengo que registrarme?
            </legend>
            <p className="text-small text-justify">
              Al registrarte podrás utilizar todos los productos que se ofrecen
              en la página. Con tu registro abrís una cuenta donde administrarás
              tus datos personales y tus apuestas a través de una billetera
              virtual. Podrás realizar tus apuestas cuando hayas realizado un
              depósito en tu cuenta.{" "}
            </p>

            <legend className="text-center mb-3">
              ¿Por qué dar mis datos?
            </legend>
            <p className="text-small text-justify">
              Es necesario que tus datos sean correctos para poder utilizar los
              productos. Debes ser mayor de 18 años y tu correo se utiliza para
              mantener tu cuenta activa en caso de olvido de contraseña o
              cualquier necesidad de comunicarnos.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA2"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA2"
              aria-expanded="false"
              aria-controls="collapseQA2"
            >
              Sobre tu Cuenta
            </div>
          </h5>
        </div>

        <div
          id="collapseQA2"
          className="collapse"
          aria-labelledby="QA2"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Como administro mi cuenta?
            </legend>
            <div className="text-small text-justify mb-3">
              Administrar tu cuenta es muy fácil.
              <br></br>
              Puedes visualizar tus datos a través de la opción "Mi cuenta" o
              "Usuario" y realizar las siguientes funciones:
              <br></br>
              <br></br>
              <ul>
                <li>Cambiar tu agencia amiga.</li>
                <li>
                  Activar o desactivar las notificaciones por correo
                  electrónico.
                </li>
                <li>
                  Cambiar tu clave de acceso, seleccionando la opción “Modificar
                  contraseña”.
                </li>
              </ul>
              Para consultar tu saldo y movimientos de tu cuenta, selecciona la
              opción "Billetera".
            </div>

            <legend className="text-center mb-3">Olvide mi contraseña</legend>
            <p className="text-small text-justify">
              Podrá generar una nueva contraseña al ingresar a la aplicación
              seleccionando la opción “¿Olvidaste tu contraseña?”. Se le enviará
              un correo con un LINK para que pueda generar su nueva clave.
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA3"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA3"
              aria-expanded="false"
              aria-controls="collapseQA3"
            >
              Depósitos y Retiros
            </div>
          </h5>
        </div>

        <div
          id="collapseQA3"
          className="collapse"
          aria-labelledby="QA3"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo realizo depósitos y retiros en mi cuenta? 
            </legend>
            En el menú de ayuda selecciona la opción "Medios de Pago" para ver
            la explicación detallada acerca de los depósitos y retiros en
            efectivo.
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA4"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA4"
              aria-expanded="false"
              aria-controls="collapseQA4"
            >
              Instalar como aplicación
            </div>
          </h5>
        </div>

        <div
          id="collapseQA4"
          className="collapse"
          aria-labelledby="QA4"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo instalo la aplicación en mi celular?
            </legend>

            <div className="m-bold mb-2">Pasos en celulares IPHONE:</div>
            <ul className="text-justify">
              <li>Accede a la aplicación desde el navegador del celular. </li>
              <li>
                Hace un click en el botón "Compartir" ubicado en la barra
                inferior.
              </li>
              <li>Selecciona la opción “Añadir a pantalla de inicio”.</li>
            </ul>

            <div className="m-bold mb-2">Pasos en otros celulares:</div>
            <ul className="text-justify">
              <li>Acceda a la aplicación desde el navegador del celular.</li>
              <li>
                Según el navegador, hace un click sobre el icono menú o flecha
                de descarga.
              </li>
              <li>
                Según el navegador, selecciona alguna de las siguientes opciones
                "Añadir página a pantalla de aplicaciones", "Añadir a pantalla
                de inicio" o "Instalar aplicación".
              </li>
            </ul>

            <div>
              Nota: Las instrucciones arriba indicadas pueden diferir según
              versión del sistema operativo y navegador.
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA5"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA5"
              aria-expanded="false"
              aria-controls="collapseQA5"
            >
              Recomendaciones de uso
            </div>
          </h5>
        </div>

        <div
          id="collapseQA5"
          className="collapse"
          aria-labelledby="QA5"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo utilizo la aplicación en mi computadora?
            </legend>

            <div className="mb-2">
              La visualización de las pantallas se ajusta al tamaño de las
              ventanas, sin embargo, para una mejor visualización se recomienda:
            </div>
            <ul className="text-justify">
              <li>Usar la aplicación con pantalla completa.</li>
              <li>Utilizar un navegador Chrome, Edge o Firefox</li>
              <li>
                Las resoluciones de pantalla recomendaddas son 1280 x 720, 1366
                x 768 y 1920 x 1080.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default preguntasFrecuentesNQN;
